import { useEffect, useState } from 'react';
import '../../assets/css/banner.less';
import icon_off from '../../assets/image/icon_off@2x.png';
import img_banner from '../../assets/image/img_chain.png';
import img_error from '../../assets/image/img_error@2x.png';
import halo from '../../assets/new-image/bannerbg02.png';
import icon_discord from '../../assets/new-image/icon_discord@2x.png';
import icon_discord_hov from '../../assets/new-image/icon_discord_hov@2x.png';
import icon_menu from '../../assets/new-image/icon_menu@2x.png';
import icon_twitter from '../../assets/new-image/icon_twitter@2x.png';
import icon_twitter_hov from '../../assets/new-image/icon_twitter_hov@2x.png';
import pc_logo from '../../assets/new-image/logo_night@2x.png';
import Button from './Button';
function Banner() {
	const socialList = [
		{
			title:'Network',
			content:[
				{
					label:'Bridge',
					link:''
				},
				{
					label:'Scan',
					link:'https://scan.testnet.initia.xyz/birdee-1'
				},
				,
				{
					label:'Faucet',
					link:'https://birdee.faucet.tucana.zone'
				}
			]
		},

		{
			title:'Documents',
			content:[
				{
					label:'Non-tech Docs',
					link:'https://tucana-1.gitbook.io/tucana-product-docs'
				},
				{
					label:'Dev Docs',
					link:''
				}
			]
		},
		{
			title:'Community',
			content:[
				{
					label:'X(Twitter)',
          icon:icon_twitter,
          icon_hov:icon_twitter_hov,
					link:'https://twitter.com/TucanaNetwork'
				},
				{
					label:'Discord',
          icon:icon_discord,
          icon_hov:icon_discord_hov,
					link:'https://discord.gg/tucana'
				}
			]
		},
		// {
		// 	title:'Blog',
		// 	content:[
		// 		{
		// 			label:'Medium',
    //       icon:icon_medium,
    //       icon_hov:icon_medium_hov,
		// 			link:'https://tucananetwork.medium.com'
		// 		}
		// 	]
		// }
	]
  const [isOpenH5,setIsOpenH5]= useState(false)
  const [isBannerDescBr,setIsBannerDescBr]= useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  useEffect(() => {
    document.addEventListener('click', (e: any) => {
      setIsOpenH5(false)
    })
    function handleResize() {
      if (window&&window.innerWidth<=440) {
        setIsBannerDescBr(true)
      }else{
        setIsBannerDescBr(false)
      }
      if (window&&window.innerWidth>810) {
        setIsOpenH5(false)
      }
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  return (
    <div className="banner">
      <div className="content">
        <div className="header">
          <div className="center">
            <img
              className="pc_logo"
              src={pc_logo}
              alt=""
            />
            <div></div>
            <div></div>
            <div></div>
            <div className="menu_box" onClick={(e:any)=>{
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              setIsOpenH5(!isOpenH5)
            }}>
              <img
                className="icon_menu"
                src={icon_menu}
                alt= ""
              />
            </div>
            <div className={isOpenH5?'h5_socical socical':"socical"}>
              {isOpenH5&&
                <img
                  className="pc_logo"
                  src={pc_logo}
                  alt=""
                />
              }
              {
                socialList.map((item,index)=>{
                  return (
                    <div key={item.title} className="item">
                      <div className="tit">{item.title}</div>
                      <div className="hover_list">
                        {
                          item.content.map((ele:any,index)=>{
                            return (
                              <div className="link-item" key={ele.label}>
                                {ele&&ele?.icon&&
                                  <img
                                    className="icon default"
                                    src={ele?.icon}
                                    alt=""
                                  />}
                                  {ele&&ele?.icon_hov&&
                                  <img
                                    className="icon hover"
                                    src={ele?.icon_hov}
                                    alt=""
                                  />}
                                <a key={ele?.label} href={ele?.link==''?'#':ele?.link} target={ele?.link==''?'_self':'_blank'} rel="noreferrer">{ele?.label}</a>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
              <div className="item">
                <div className="link-item blog-item">
                  <a href='https://tucananetwork.medium.com' target='_blank' className='tit blog' rel="noreferrer">Blog</a>
                </div>
              </div>
            </div>
            <div onClick={openModal} className="launch_btn pc_btn">
              <Button  text='Launch App'/>
            </div>
            {/* <a href="https://birdee.ag.tucana.zone/" target='_blank' className="launch_btn pc_btn" rel="noreferrer"> 
            <Button  text='Launch App'/>
            </a> */}
            {isOpen && (
             <ErrorModal closeModal={closeModal} />
            )}
          </div>
        </div>
        <div className="banner_text">
          <div className="center">
            {
              windowWidth>750 && (
                <>
                  <p>A MODULAR CHAIN</p>
                  <p>FOR TRADING</p>
                </>
              )
            }
            {
              windowWidth<=750 && (
                <div className='title'>A MODULAR CHAIN <br/> FOR TRADING</div>
              )
            }
           
            {windowWidth>1920&&<span className='desc'>The Liquidity and Trading Host of the Modular Ecosystem</span>}
            {windowWidth>1440 && windowWidth<=1920&&<span className='desc'>The Liquidity and Trading Host of the<br/> Modular Ecosystem</span>}
            {windowWidth<=1440&&<span className='desc'>The Liquidity and Trading Host of <br/> the Modular Ecosystem</span>}
            {/* <a href="https://birdee.ag.tucana.zone/" target='_blank' className="launch_btn h5_btn" rel="noreferrer">
              <Button text='Launch App'/>
            </a> */}
            <div onClick={openModal}  className="launch_btn h5_btn">
              <Button  text='Launch App'/>
            </div>
          </div>
          <div className='banner_bg'>
            <img src={img_banner} alt="" />
            <video   
              muted
              playsInline
              loop
              autoPlay
              data-wf-ignore="true"
              data-object-fit="cover">
                <source
                  src="/banner_bg.mp4"
                  type="video/mp4"
                />
            </video>
          </div>
          <img src={halo} className='banner_halo' alt='Halo' />
        </div>
      </div>
    </div>
  );
}
const ErrorModal = ({closeModal}:{closeModal:()=>void})=>{
  return (
  <div  className="modal_style">
    <div className="modal_content">
    <div className="icon_box">
      <img
        className="icon"
        src={icon_off}
        alt=""
        onClick={closeModal}
      />
    </div>
    <div>
      <img
        className="img_error"
        src={img_error}
        alt=""
      />
    </div>
    <div className="text">
      Tucana services have been terminated.
    </div>
    <div className="text">
     We are sorry for the inconvenience.
    </div>
    </div>
  </div>
  )
}
export default Banner;
